<template lang="pug">
  div
    v-data-table(
      :headers="headers"
      :items="files"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{ 'items-per-page-options': [5, 20, 25, 50, 100] }"
      class="elevation-1"
      disable-sort
    )
      template(#item="{ item }")
        tr
          td
            router-link(:to="fileEditRoute(item)") {{ item.name }}
          td(class="text-no-wrap") {{ item.size }}
          td(class="text-no-wrap") {{ item.addedDate }}
          td(class="text-no-wrap text-right")
            v-btn(
              :href="item.url"
              small
              icon
              class="mr-2"
              target="_blank"
              title="Descarca"
            )
              v-icon(small) cloud_download
            v-btn(
              @click="editFile(item)"
              small
              icon
              class="mr-2"
              title="Editeaza"
            )
              v-icon(small) edit
    router-view(:labels="labels" :label="label")
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    label: {
      type: Number,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      files: [],
      loading: false,
      headers: [
        { text: 'Nume', value: 'name' },
        { text: 'Marime', value: 'size' },
        { text: 'Data adaugarii', value: 'addedDate' },
        { text: '', value: 'action', sortable: false }
      ],
      options: {
        page: 1,
        perPage: 10
      },
      total: 0
    };
  },
  computed: {
    ...mapState({
      user: s => s.auth.user
    })
  },
  watch: {
    options: {
      handler () {
        this.loadFiles();
      },
      deep: true
    }
  },
  methods: {
    fileEditRoute (file) {
      return { name: 'files.edit', params: { id: file.id } };
    },
    editFile (file) {
      this.$router.push(this.fileEditRoute(file));
    },
    loadFiles () {
      this.loading = true;

      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage
      };

      return this.axios.get(`institutions/${this.user.institution.id}/labels/${this.label}/files`, { params })
        .then(res => {
          this.files = res.data.data;
          this.total = res.data.meta.pagination.total;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
