<template lang="pug">
  div
    v-card-text
      v-select(
        :value="id"
        @input="listFiles"
        :items="labels"
        placeholder="Selecteaza eticheta..."
        item-text="name"
        item-value="id"
        class="label-select"
      )
        template(#item="{ item }")
          div(:style="paddingStyle(item.depth)") {{ item.name }}
      v-btn(
        v-if="id"
        color="primary"
        @click="showIntegrationCode = !showIntegrationCode"
      )
        v-icon(left) code
        span Coduri integrare
    v-slide-y-transition
      v-card-text(v-show="showIntegrationCode" class="pt-0")
        div(class="subtitle-2")
          v-btn(small icon :href="siteUrl" target="_blank")
            v-icon link
          span Integrare link catre site
        v-text-field(
          class="mt-2"
          label="Faceti click pentru copierea codului"
          :value="siteIntegrationCode"
          @click="copyCode($event)"
          readonly
          outlined
          hide-details
        )
        div(class="subtitle-2 mt-4")
          v-btn(small icon :href="institutionFrameUrl" target="_blank")
            v-icon link
          span Integrare iframe institutie
        v-text-field(
          class="mt-2"
          label="Faceti click pentru copierea codului"
          :value="institutionIntegrationCode"
          @click="copyCode($event)"
          readonly
          outlined
          hide-details
        )
        div(class="subtitle-2 mt-4")
          v-btn(small icon :href="labelFrameUrl" target="_blank")
            v-icon link
          span Integrare iframe eticheta
        v-text-field(
          class="mt-2"
          label="Faceti click pentru copierea codului"
          :value="labelIntegrationCode"
          @click="copyCode($event)"
          readonly
          outlined
          hide-details
        )
    router-view(:labels="labels" :label="id")
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    id: {
      validator: prop => typeof prop === 'number' || prop === null,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      minHeight: '720px',
      showIntegrationCode: false
    };
  },
  computed: {
    ...mapState({
      user: s => s.auth.user
    }),
    institutionFrameUrl () {
      return `${process.env.VUE_APP_APP_URL}/#/public/institutions/${this.user.institution.id}`;
    },
    labelFrameUrl () {
      return `${this.institutionFrameUrl}/labels/${this.id}`;
    },
    siteUrl () {
      return `${process.env.VUE_APP_APP_URL}/#/public/site/institutions/${this.user.institution.id}`;
    },
    institutionIntegrationCode () {
      return `<iframe src="${this.institutionFrameUrl}" style="width:100%;min-height:${this.minHeight};border:0;" scrolling="no"></iframe>`;
    },
    labelIntegrationCode () {
      return `<iframe src="${this.labelFrameUrl}" style="width:100%;min-height:${this.minHeight};border:0;" scrolling="no"></iframe>`;
    },
    siteIntegrationCode () {
      return `<a href="${this.siteUrl}" title="Monitorul Oficial Local">Monitorul Oficial Local</a>`;
    }
  },
  mounted () {
    if (this.id) {
      this.listFiles(this.id);
    }
  },
  methods: {
    copyCode ($event) {
      $event.target.select();
      if (document.execCommand('copy', false, $event.target.value)) {
        this.$success('Codul a fost copiat.');
      } else {
        this.$error('Codul nu a putut fi copiat.');
      }
    },
    paddingStyle (depth) {
      return depth ? `padding:12px 16px 12px ${depth * 16}px` : '';
    },
    listFiles (id) {
      this.$router.push({ name: 'labels.files', params: { id } });
    }
  }
};
</script>
<style>
  .label-select .v-select__selections {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
