<template lang="pug">
  v-container(fluid)
    v-card
      v-toolbar(:color="theme.colors.primary" dark)
        v-toolbar-title Lista etichete
        div(class="flex-grow-1")
        template
        v-btn(
          :to="{ name: 'files.create' }"
          icon
          exact
          title="Urca fisier"
        )
          v-icon cloud_upload
      router-view(:key="$route.params.id || 1" :labels="labels")
</template>
<script>
import { mapState } from 'vuex';

export default {
  data () {
    return {
      labels: []
    };
  },
  computed: {
    ...mapState({
      theme: s => s.config.theme
    })
  },
  mounted () {
    this.loadLabels();
  },
  methods: {
    loadLabels () {
      this.$store.commit('pageLoading', true);

      return this.axios.get('/labels')
        .then(res => {
          this.labels = res.data.data;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.$store.commit('pageLoading', false);
        });
    }
  }
};
</script>
